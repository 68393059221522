import React from "react";
import LayoutMain from "../../layouts/LayoutMain";
import Seo from "../../components/seo";
import Feature from "../../components/ui/Feature.js";
import ChartSingle from "../../components/chart/interactions/basic-single/Chart.js";
import ChartSchematic from "../../components/chart/interactions/basic-schematic/Chart.js";
import ChartDeck from "../../components/chart/interactions/basic-deck/Chart.js";
import BrokenLamp from "../../client/examples/json/broken-lamp.json";
import BrokenLampSingle from "../../client/examples/json/broken-lamp-single.json";
import BrokenLampDeck from "../../client/examples/json/broken-lamp-deck.json";
import { Row, Col, Divider, Tag, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import LearnMore from "../../components/sections/LearnMore.js";
const { Title, Paragraph, Text } = Typography;

const FeaturesChartsPage = () => (
  <LayoutMain>
    <Seo
      title="Features | Charts"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      align="middle"
      className="heading"
      style={{ backgroundColor: "#fff" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title>Charts</Title>
        <Title level={2} style={{ color: "white" }}>
          inFlow Features
        </Title>
      </Col>
    </Row>
    <Row justify="center" className="section section-text">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Paragraph style={{ margin: "0" }}>
          From simple business tool to extensive medical algorithm,
          <br />
          we offer multiple types of charts which serve your use-case perfectly.
        </Paragraph>
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row
      justify="center"
      className="section"
      style={{ backgroundColor: "#f1f3f5" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title level={3}>
          Single{" "}
          {/* <Tag>
            Lite
          </Tag> */}
        </Title>
        <div
          style={{
            backgroundColor: "white",
            padding: "16px",
            marginBottom: "24px",
          }}
        >
          <ChartSingle entry={BrokenLampSingle} firstNodeId="node1single" />
        </div>
        <Text>
          Works great for:{" "}
          <Tag icon={<CheckOutlined />}>fast decision making</Tag>
          <Tag icon={<CheckOutlined />}>user-facing tools</Tag>
          <Tag icon={<CheckOutlined />}>support & troubleshooting</Tag>
        </Text>
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title level={3}>
          Schematic
          {/* <Tag>Pro</Tag> */}
        </Title>
        <div
          style={{
            padding: "16px",
            backgroundColor: "#f1f3f5",
            marginBottom: "24px",
          }}
        >
          <ChartSchematic entry={BrokenLamp} firstNodeId="node1" />
        </div>
        <Text>
          Works great for: <Tag icon={<CheckOutlined />}>education</Tag>
          <Tag icon={<CheckOutlined />}>documentation</Tag>
          <Tag icon={<CheckOutlined />}>research</Tag>
        </Text>
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row
      justify="center"
      className="section"
      style={{ backgroundColor: "#f1f3f5" }}
    >
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Title level={3}>
          Path
          {/* <Tag>Lite</Tag> */}
        </Title>
        <div
          style={{
            backgroundColor: "white",
            padding: "16px",
            marginBottom: "24px",
          }}
        >
          <ChartDeck entry={BrokenLampDeck} firstNodeId="node1deck" />
        </div>
        <Text>
          Works great for: <Tag icon={<CheckOutlined />}>all industries</Tag>
          <Tag icon={<CheckOutlined />}>extensive processes</Tag>
          <Tag icon={<CheckOutlined />}>forms</Tag>
        </Text>
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row justify="center" className="section">
      <Col xs={24} sm={20} md={20} lg={16} xl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{ marginTop: "24px", marginBottom: "36px" }}
              className="typography-nice alt"
            >
              User-friendly &amp; beautiful
            </Title>
          </Col>
          <Feature
            title="Mobile first"
            description="Through tailored interactions all our tools work great on smaller devices."
          />
          <Feature
            title="Data driven"
            description="Run multiple types of charts from a single source of data."
          />
          <Feature
            title="Highly customizable"
            description="Our charts are highly customizable. Contact us if you need something special."
          />
        </Row>
      </Col>
    </Row>
    <Divider dashed type={{ margin: "0" }} />
    <LearnMore />
  </LayoutMain>
);

export default FeaturesChartsPage;
