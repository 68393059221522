import React, { useState } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./Nodes";
import ArrowsActive from "../../links/arrow/ArrowsActive";
import { Row } from "antd";

import { Xwrapper } from "react-xarrows";
import * as Scroll from "react-scroll";

import { ContentComponents } from "../../mapping/ContentComponents";
import { ContentLinkExternal } from "../../mapping/ContentLinkExternal";
import { ClientExamplesComponents } from "../../mapping/ClientExamplesComponents";
import { ClientLtaComponents } from "../../mapping/ClientLtaComponents";

import OptionsItemsPointsComponent from "../../links/options/OptionsItemsPointsComponent";
import OptionsSelectComponent from "../../links/options/OptionsSelectComponent";
// import { OptionComponents } from "../../mapping/OptionComponents";

import "./styles.scss";

const scroller = Scroll.scroller;

export default function ChartDeck({ entry, firstNodeId, language }) {
  const [nodePath, setNodePath] = useState([firstNodeId]);
  const [isLastOnPath, setIsLastOnPath] = useState(firstNodeId);

  const onNext = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition === -1) {
      const addNextNode = nodePath.concat([nodeId]);
      setNodePath(addNextNode);
      const nextNodeId = nodeId.toString();
      setIsLastOnPath(nextNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, 10);
        }
      }, 50);
    } else {
      onGoTo(nodeId);
    }
  };

  const onGoTo = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition !== -1) {
      const goToNode = nodePath.slice(0, nodeCurrentPosition + 1);
      setNodePath(goToNode);
      const goToNodeId = nodeId.toString();
      setIsLastOnPath(goToNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onPrevious = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const previousNode = nodePath.slice(0, nodeCurrentPosition);
    if (previousNode.length > 0) {
      setNodePath(previousNode);
      const previousNodeId = nodePath[nodePath.length - 2].toString();
      setIsLastOnPath(previousNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(previousNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onReset = () => {
    const resetNode = firstNodeId;
    setNodePath([resetNode]);
    const resetNodeId = firstNodeId.toString();
    setIsLastOnPath(resetNodeId);
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.25 * windowHeight;
    setTimeout(function () {
      if (!("parentIFrame" in window)) {
        scroller.scrollTo(resetNodeId, {
          duration: 300,
          delay: 25,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }
    }, 50);
    setTimeout(function () {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, -90);
      }
    }, 50);
  };

  const mapping = {
    chart: ({ nodes }) => <>{nodes}</>,
    node: ({
      content,
      disableDefaultOptionsButtons,
      extraContent,
      extraContentTitle,
      isEndNode,
      links,
      nodeId,
      nodeTitle,
      options,
    }) => (
      <>
        <Node
          disableDefaultOptionsButtons={disableDefaultOptionsButtons}
          extraContent={extraContent}
          extraContentTitle={extraContentTitle}
          isEndNode={isEndNode}
          isFirstOnPath={firstNodeId === nodeId}
          isLastOnPath={isLastOnPath === nodeId}
          isOnPath={nodePath.indexOf(nodeId) !== -1}
          key={nodeId}
          language={language}
          links={links}
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          onGoTo={onGoTo}
          onNext={onNext}
          onPrevious={onPrevious}
          onReset={onReset}
          options={options}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
        >
          {content}
        </Node>
        <ArrowsActive
          arrowColorActive="#40c057"
          arrowColorInactive="#adb5bd"
          gridBreak="70%"
          isLastOnPath={isLastOnPath}
          links={links}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
          nodeId={nodeId}
        />
      </>
    ),
    ...ClientExamplesComponents,
    ...ClientLtaComponents,
    ...ContentComponents,
    ...ContentLinkExternal,
    // ...OptionComponents,
    OptionsSelectComponent: ({ steps, title }) => (
      <OptionsSelectComponent
        language={language}
        onNext={onNext}
        steps={steps}
        title={title}
      />
    ),
    OptionsItemsPointsComponent: ({
      cutoffValue,
      nodeLinkOne,
      nodeLinkOneLabel,
      nodeLinkTwo,
      nodeLinkTwoLabel,
      steps,
      title,
    }) => (
      <OptionsItemsPointsComponent
        cutoffValue={cutoffValue}
        language={language}
        nodeLinkOne={nodeLinkOne}
        nodeLinkOneLabel={nodeLinkOneLabel}
        nodeLinkTwo={nodeLinkTwo}
        nodeLinkTwoLabel={nodeLinkTwoLabel}
        onNext={onNext}
        steps={steps}
        title={title}
      />
    ),
  };

  return (
    <Xwrapper>
      <Row type="flex" className="chart-basic-deck">
        <ReactFromJSON entry={entry} mapping={mapping} />
        <div id="bottom"></div>
      </Row>
    </Xwrapper>
  );
}
